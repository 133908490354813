
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { commonModule, companyModule, installationsModule, itemsModule } from '@/store/modules/store-accessor'
import { setGoogleMapsInfoToModel } from "@/helpers/data";
import MapAddressCoordinates from '@/components/Map/MapAddressCoordinates.vue'

@Component({
  components: {
    MapAddressCoordinates
  }
})
export default class AddInstallationModal extends Vue {
  @Prop() payload: any

  isAddingInstallation = false;
  markerCoords = [37.983810, 23.727539];
  lat = this.markerCoords[0];
  long = this.markerCoords[1];

  installationModel = {
   tag: this.installationNextTagId,
   client: {name: "", id: 0},
   owner: {name: "", id: 0},
   address: "",
   area: "",
   zip_code: "",
   floor: "",
   is_premium: false,
   notes: "",
   latitude: "",
   longitude: "",
   description: '',
   selected_item: {id: 0, name: '', item_type_id: 0, warranty: '', is_primary: false, is_active: false, serial_no: ''},
  }


  tagRule= [
    (val: any) => !!val || this.$i18n.t("tag_required"),
  ]
  clientRule= [
    (val: any) => ((!!val || val == '') && val.name.length > 0) || this.$i18n.t("tenant_required"),
  ]

  areaRule= [
    (val: any) => !!val || this.$i18n.t("area_required"),
    (val: any) => val.length <= 30 || this.$i18n.t("form_area_length_rule"),
  ]

  addressRule= [
    (val: any) => val?.length <= 100 || this.$i18n.t("form_address_length_rule"),
  ]

  zipCodeRule= [
    (val: any) => val?.length <= 15 || this.$i18n.t("form_zipCode_length_rule"),
  ]

  notesRule= [
    (val: any) => val.length <= 1000 || this.$i18n.t("notesLengthRule"),
  ]

  descriptionRule = [
    (val: any) => val.length <= 80 || this.$i18n.t("installationDescriptionLengthRule"),
  ]

  serialNoRule = [
    (val: any) => {
      if (val === undefined || val === null) {
        return true;
      }
      return val.length <= 50 || this.$i18n.t("form_serialNumber_length_rule");
    },
  ];


  get clientsCombo () {
    return commonModule.clientsCombo.map((item: any) => {
      let client_arr = item.is_business
      ? [`${item.entity_name} (${item.name})`]
      : [`${item.name}`]
      return {...item, name: client_arr.filter(Boolean).join(' | ') }
    });
  }

  get installationNextTagId () {
      return installationsModule.installationNextTagId;
  }

  async hideModal () {
    commonModule.hideModal();
    installationsModule.clearNewInstallationCoordinates();
  }

  async mounted() {
    commonModule.initSnackbar({});
    await commonModule.getClientsCombo();
    await companyModule.getCompanyInfo();
    await itemsModule.getAllInventoryItems('');
  }

  doAddInstallation() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.isAddingInstallation = true;

      // Installation Item
      if(this.installationModel.selected_item.item_type_id) {
        this.installationModel.selected_item.is_primary = true;
        this.installationModel.selected_item.is_active = true;
      }

      installationsModule.addInstallation(this.installationModel);
      this.hideModal();
    }
  }

  get installationCooridnates() {
    return installationsModule.newInstallationCoordinates;
  }

  get installationItemsCombo () {
    return itemsModule.installationItems;
  }

  @Watch('installationCooridnates')
  updateCoordinates() {
    if(installationsModule.newInstallationCoordinates.length == 2) {
      setGoogleMapsInfoToModel(this.installationModel, installationsModule.newInstallationCoordinates[0], installationsModule.newInstallationCoordinates[1]);
    }
  }

  getAddressData(addressData: any) {
    this.installationModel.area = addressData?.locality ?? '';
    this.installationModel.zip_code = addressData.postal_code ? addressData.postal_code.replace(/\s/g, "") : '';
    this.installationModel.latitude = addressData?.latitude;
    this.installationModel.longitude = addressData?.longitude;

    this.markerCoords = [parseFloat(this.installationModel?.latitude), parseFloat(this.installationModel?.longitude)];
    this.lat = this.markerCoords[0];
    this.long = this.markerCoords[1];
  }

  updateAddressField(addressInfo: any) {
    this.installationModel.address = addressInfo.address;
    this.installationModel.area = addressInfo.area;
    this.installationModel.zip_code = addressInfo.zip_code;
    this.installationModel.latitude = addressInfo.latitude;
    this.installationModel.longitude = addressInfo.longitude;
  }

  isNumber(evt: any) {
    evt = evt || window.event;
    let charCode = evt.which || evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    } else {
      return true;
    }
    return false;
  }

  get descriptionIsEnabled() {
    return companyModule.company?.preferences?.installation_description;
  }

};
