import i18n from "@/i18n";
import dayjs from "dayjs";
import hexRgb from "hex-rgb";
import duration from "dayjs/plugin/duration";
import 'dayjs/locale/el';
import 'dayjs/locale/en';

dayjs.extend(duration);

function formatDate(dateTime: string) {
  if (dateTime !== null && dateTime !== undefined) {
    return dayjs(dateTime).format("MMM DD, YYYY HH:mm:ss");
  } else {
    return "-";
  }
}

function formatDateCalendar(dateTime: Date) {
  if (dateTime !== null && dateTime !== undefined) {
    return dayjs(dateTime).format("YYYY-MM-DD HH:mm");
  } else {
    return "-";
  }
}

function formatOnlyDate(dateTime: Date) {
  if (dateTime !== null && dateTime !== undefined) {
    return dayjs(dateTime).locale(localStorage.getItem('lang') || 'el').format("MMM DD, YYYY");
  } else {
    return "";
  }
}

function formatDateWithoutSeconds(dateTime: Date) {
  if (dateTime !== null && dateTime !== undefined) {
    return dayjs(dateTime).locale(localStorage.getItem('lang') || 'el').format("MMM DD, YYYY HH:mm");
  } else {
    return "";
  }
}

function convertMinsToHrsMins(mins: number) {
  const hours = mins / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  if (rhours == 0) {
    if (rminutes < 10) {
      return rminutes + "m";
    } else {
      return ("0" + rminutes).slice(-2) + "m";
    }
  } else if (rminutes == 0) {
    return rhours + "h";
  } else {
    return rhours + "h " + ("0" + rminutes).slice(-2) + "m";
  }
}

function calcDuration(start: Date, end: Date) {
  if (
    start !== null ||
    start !== undefined ||
    end !== null ||
    end !== undefined
  ) {
    const newStart = dayjs(start);
    const newEnd = dayjs(end);

    const duration = dayjs.duration(newEnd.diff(newStart));

    const durationHoursMinutes = convertMinsToHrsMins(duration.asMinutes());

    return durationHoursMinutes;
  } else {
    return "-";
  }
}

function mergedAddressAndArea(address: string, area: string) {
  return address || area ? [address, area].filter(Boolean).join(", ") : "-";
}

function appointmentStatusToName(status: string) {
  if (status == "1") return "appointmentCanceled";
  else if (status == "2") return "appointmentPending";
  else if (status == "3") return "appointmentOngoing";
  else if (status == "4") return "appointmentComplete";
  else if (status == "5") return "appointmentInComplete";
  else return "appointmentPending";
}

function appointmentStatusToColor(status: string) {
  if (status == "1") return "appointmentStatusError";
  else if (status == "2") return "appointmentTypeError";
  else if (status == "3") return "info";
  else if (status == "4") return "success";
  else if (status == "5") return "appointmentInCompleteColor";
  else return "appointmentTypeError";
}

function addOpacity(color: any, opacity: number): string {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

function roundMinutes(minutes: number) {
  if (minutes >= 59 || minutes === 0) return "00";
  minutes = Math.ceil(minutes / 15) * 15 - 15;
  if (minutes < 10) return `0${minutes}`;
  else return minutes.toString();
}

function sortDateRange(dateRange: any) {
  const startDate = dateRange[0];
  const endDate = dateRange[1];

  const startDateConv = new Date(startDate);
  const endDateConv = new Date(endDate);

  if (startDateConv <= endDateConv) {
    dateRange[0] = startDate;
    dateRange[1] = endDate;
  } else {
    dateRange[0] = endDate;
    dateRange[1] = startDate;
  }
}

function formatDatesRangeText(startDate: any, endDate: any) {
  if (!startDate) return null;

  const dateArray = [dayjs(startDate)];

  if (endDate) {
    dateArray.push(dayjs(endDate));
  }

  dateArray.sort((a, b) => (a.isAfter(b) ? 1 : -1));

  const formattedDates = dateArray.map((date) => date.format("DD/MM/YYYY"));

  if (!endDate) {
    formattedDates.push("");
  }

  return formattedDates.join(" - ");
}

function primaryContactExists(model: any) {
  let exists = false;
  model.contacts.forEach((contact: any) => {
    if (contact.is_primary == true) {
      exists = true;
    }
  });
  return exists;
}

function statusCodeMsg(statusCode: string) {
  const STATUS_CODES: any = {
    "400": "400_BAD_REQUEST_ERROR_API",
    "401": "401_UNAUTHORIZED_ERROR_API",
    "403": "403_FORBIDDEN_ERROR_API",
    "404": "404_NOT_FOUND_ERROR_API",
    "406": "406_NOT_ACCEPTED_ERROR_API",
    "409": "409_CONFLICT_ERROR_API",

    "500": "500_INTERNAL_SERVER_ERROR_API",
    "502": "502_BAD_GATEWAY_ERROR_API",
    "503": "503_SERVICE_UNAVAILABLE_API",
  };

  if (STATUS_CODES[statusCode]) {
    return `${i18n.t(STATUS_CODES[statusCode])} ${statusCode}`;
  }

  return "API_GENERAL_ERROR";
}

function taskStatusToName(status: number) {
  if (status == 1) return "taskCanceled";
  else if (status == 2) return "taskPending";
  else if (status == 3) return "taskCompleted";
  else if (status == 4) return "taskCompletedWithAppointment";
  else if (status == 5) return "taskCompletedWithJob";
}

function taskStatusToColor(status: number) {
  if (status == 1) return "taskStatusError";
  else if (status == 2) return "taskTypeError";
  else if (status == 3) return "success";
  else if (status == 4) return "taskCompletedWithAppointmentColor";
  else if (status == 5) return "taskCompletedWithJobColor";
  else return "taskTypeError";
}

function formatAmount(amount: number) {
  if (amount === undefined) {
    return 0;
  } else if (amount - Math.floor(amount) !== 0) {
    return (Math.round(amount * 100) / 100).toFixed(2);
  } else {
    return amount;
  }
}

function customSearch(value: any, search: any, item: any) {
  return Object.values(item).some(
    (v: any) =>
      v &&
      v
        .toString()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replaceAll("a", "α")
        .replaceAll("b", "β")
        .replaceAll("c", "ψ")
        .replaceAll("d", "δ")
        .replaceAll("e", "ε")
        .replaceAll("f", "φ")
        .replaceAll("g", "γ")
        .replaceAll("h", "η")
        .replaceAll("i", "ι")
        .replaceAll("j", "ξ")
        .replaceAll("k", "κ")
        .replaceAll("l", "λ")
        .replaceAll("m", "μ")
        .replaceAll("n", "ν")
        .replaceAll("o", "ο")
        .replaceAll("p", "π")
        .replaceAll("r", "ρ")
        .replaceAll("s", "σ")
        .replaceAll("t", "τ")
        .replaceAll("u", "υ")
        .replaceAll("v", "ν")
        .replaceAll("w", "ω")
        .replaceAll("x", "χ")
        .replaceAll("y", "υ")
        .replaceAll("z", "ζ")
        .replaceAll("ω", "ο")
        .includes(
          search
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replaceAll("a", "α")
            .replaceAll("b", "β")
            .replaceAll("c", "ψ")
            .replaceAll("d", "δ")
            .replaceAll("e", "ε")
            .replaceAll("f", "φ")
            .replaceAll("g", "γ")
            .replaceAll("h", "η")
            .replaceAll("i", "ι")
            .replaceAll("j", "ξ")
            .replaceAll("k", "κ")
            .replaceAll("l", "λ")
            .replaceAll("m", "μ")
            .replaceAll("n", "ν")
            .replaceAll("o", "ο")
            .replaceAll("p", "π")
            .replaceAll("r", "ρ")
            .replaceAll("s", "σ")
            .replaceAll("t", "τ")
            .replaceAll("u", "υ")
            .replaceAll("v", "ν")
            .replaceAll("w", "ω")
            .replaceAll("x", "χ")
            .replaceAll("y", "υ")
            .replaceAll("z", "ζ")
            .replaceAll("ω", "ο")
        )
  );
}

function randomHexColor() {
  const randomHexColor = Math.floor(Math.random() * 16777215);
  const hexColor = randomHexColor.toString(16).padStart(6, "0");

  return `#${hexColor}`;
}

function hexColorRemoveLastTwoDigits(hexColor: string) {
  if (hexColor.length === 9 && hexColor.toUpperCase().slice(7) === "FF") {
    return hexColor.slice(0, 7);
  } else {
    return hexColor;
  }
}

function entryIcon(action: any) {
  switch (action.entry_type) {
    case "action":
      return "mdi-account-wrench-outline";
    case "payment":
      return "mdi-cash-sync";
    case "material":
      return "mdi-hammer-screwdriver";
    case "expense":
      return "mdi-cash-off";
    default:
      return "";
  }
}

function getOptimalTextColor(hexColorString: string) {
  const { red, green, blue, alpha } = hexRgb(hexColorString);

  //Commented is the 2nd option that complies with w3c standards for accessibility
  //We selected the 1st option because it is not aggressive and select white text more frequently
  // const uicolors = [red / 255, green / 255, blue / 255];
  // const c = uicolors.map((col) => {
  //   if (col <= 0.03928) {
  //     return col / 12.92;
  //   }
  //   return Math.pow((col + 0.055) / 1.055, 2.4);
  // });
  // const L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
  // return (L > 0.179) ? 'black' : 'white';
  return red * 0.299 + green * 0.587 + blue * 0.114 > 186 ? "black" : "white";
}

function sortTableDates(a: string, b: string) {
  const dateA: any = new Date(a);
  const dateB: any = new Date(b);
  return dateA - dateB;
}

function isDecimalNumber(evt: any) {
  // Prevent user from entering negative values (-1) and characters
  const theEvent = evt || window.event;
  let key = theEvent.keyCode || theEvent.which;
  key = String.fromCharCode(key);
  if (key.length == 0) return;
  const regex = /^[0-9.,\b]+$/;
  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
}

function taskColor(task: Record<string, any>) {
  if (!task.due_at || [3, 4, 5].includes(task.task_status_id)) {
    return 'textGrey';
  } else if (!task.all_day && dayjs(task.due_at).isSame(dayjs(), 'day') && dayjs(task.due_at).isAfter(dayjs())) {
    return 'darkGreen';
  } else if (task.all_day && dayjs(task.due_at).isSame(dayjs(), 'day')) {
    return 'darkGreen';
  } else if (dayjs(task.due_at).isAfter(dayjs())) {
    return 'futureTask';
  } else {
    return 'pastTask';
  }
}

async function setGoogleMapsInfoToModel(
  modelName: Record<string, any>,
  latitude: any,
  longitude: any
) {
  let lang = localStorage.getItem("lang") || "el";
  if (lang === "gr") {
    lang = "el";
  }

  try {
    const googleKey =
      process.env.NODE_ENV === "production"
        ? "GOOGLE_MAPS_KEY"
        : "AIzaSyDbgvgejSNtTI410Z1ihbNGl-0iEpfPl0k";
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&language=${lang}&key=${googleKey}`;

    const response = await fetch(apiUrl);
    const responseData = await response.json();

    if (
      responseData &&
      responseData.results &&
      responseData.results.length > 0
    ) {
      const result = responseData.results[0];

      const { lat, lng } = result.geometry.location;
      const address = result.formatted_address;
      let area = "";
      for (const component of result.address_components) {
        if (
          component.types.includes("locality") ||
          component.types.includes("political")
        ) {
          area = component.long_name;
          break;
        }
      }

      let zipCode = "";
      for (const component of result.address_components) {
        if (component.types.includes("postal_code")) {
          zipCode = component.long_name.replace(/ /g, "");
          break;
        }
      }

      modelName.address = address;
      modelName.area = area;
      modelName.latitude = lat;
      modelName.longitude = lng;
      modelName.zip_code = zipCode;
    }
  } catch (error) {
    console.error("Error fetching data from Google Maps API");
    return 0;
  }
}

async function getGoogleMapsInfo(latitude: any, longitude: any) {
  let lang = localStorage.getItem('lang') || 'el';

  const mapModel = {
    address: '',
    area: '',
    latitude: null,
    longitude: null,
    zip_code: null as any
  };

  if(lang === 'gr') {
    lang = 'el';
  }

  try {
    const googleKey = (process.env.NODE_ENV === "production") ? "GOOGLE_MAPS_KEY" : "AIzaSyDbgvgejSNtTI410Z1ihbNGl-0iEpfPl0k";
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&language=${lang}&key=${googleKey}`;

    const response = await fetch(apiUrl);
    const responseData = await response.json();

    if (responseData && responseData.results && responseData.results.length > 0) {
      const result = responseData.results[0];

      const { lat, lng } = result.geometry.location;
      const address = result.formatted_address;
      let area = '';
      for (const component of result.address_components) {
        if (component.types.includes('locality') || component.types.includes('political')) {
          area = component.long_name;
          break;
        }
      }

      let zipCode = '';
      for (const component of result.address_components) {
        if (component.types.includes('postal_code')) {
          zipCode = component.long_name.replace(/ /g,'');
          break;
        }
      }

      mapModel.address = address;
      mapModel.area = area;
      mapModel.latitude = lat;
      mapModel.longitude = lng;
      mapModel.zip_code = zipCode;

      return mapModel;
    }
    else {
      return mapModel;
    }
  } catch (error) {
    console.error('Error fetching data from Google Maps API');
    return mapModel;
  }
}

function periodToText(period: number) {
  if (period == 1) {
    return "D";
  } else if (period == 7) {
    return "W";
  } else if (period == 30) {
    return "M";
  } else if (period == 365) {
    return "Y";
  } else {
    return "D";
  }
}

function periodOptionToNumber(option: string) {
  if (option) {
    return option.replace(/[^0-9]/g, "");
  } else {
    return "";
  }
}

function periodOptionToComboText(option: string) {
  if (option) {
    if (parseInt(option) > 1) {
      return option.replace(/[^A-Z]/g, "") == "D"
        ? i18n.t("days")
        : option.replace(/[^A-Z]/g, "") == "W"
        ? i18n.t("weeks")
        : option.replace(/[^A-Z]/g, "") == "M"
        ? i18n.t("months")
        : i18n.t("Years");
    } else {
      return option.replace(/[^A-Z]/g, "") == "D"
        ? i18n.t("day")
        : option.replace(/[^A-Z]/g, "") == "W"
        ? i18n.t("week")
        : option.replace(/[^A-Z]/g, "") == "M"
        ? i18n.t("month")
        : i18n.t("Year");
    }
  } else {
    return "";
  }
}

function periodOptionToComboValue(option: string) {
  if (option) {
    return option.replace(/[^A-Z]/g, "") == "D"
      ? 1
      : option.replace(/[^A-Z]/g, "") == "W"
      ? 7
      : option.replace(/[^A-Z]/g, "") == "M"
      ? 30
      : 365;
  } else {
    return 0;
  }
}

function isNumber(evt: any) {
  evt = evt || window.event;
  const charCode = evt.which || evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    evt.preventDefault();
  } else {
    return true;
  }
  return false;
}

function periodOptionToLabel(option: string) {
  if (option) {
    const period_num = option.replace(/[^0-9]/g, "");
    const period_opt = option.replace(/[^A-Z]/g, "");

    if (period_num == "1") {
      return period_opt == "D"
        ? period_num + " " + i18n.t("day")
        : period_opt == "W"
        ? period_num + " " + i18n.t("week")
        : period_opt == "M"
        ? period_num + " " + i18n.t("month")
        : period_num + " " + i18n.t("Year");
    } else {
      return period_opt == "D"
        ? period_num + " " + i18n.t("days")
        : period_opt == "W"
        ? period_num + " " + i18n.t("weeks")
        : period_opt == "M"
        ? period_num + " " + i18n.t("months")
        : period_num + " " + i18n.t("Years");
    }
  } else {
    return "-";
  }
}

function getDurationText(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  let duration = "";

  if (hours > 0) {
    duration += hours + "h";
    if (mins > 0) {
      duration += " ";
    }
  }

  if (mins > 0) {
    duration += mins + "m";
  }

  return duration;
}

function isGasCompany(info: Record<string, any>) {
  return info.text === "GasCompany";
}

function pagesExportLimit() {
  return 500;
}

function templatesFilenameLanguage() {
  let selectedLanguage = localStorage.getItem("lang") || "el";
  selectedLanguage = selectedLanguage === "gr" ? "el" : selectedLanguage!; // for legacy reasons
  return selectedLanguage.toUpperCase();
}

function mapTileProviders() {
  const tileProviders = [
    {
      name: i18n.t("ground"),
      visible: true,
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    },
    {
      name: i18n.t("satellite"),
      visible: false,
      url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      attribution:
        "Esri, Maxar, Earthstar Geographics, and the GIS User Community",
    },
  ];
  return tileProviders;
}

function timeChargedUnitOption(unit: string) {
  const options: any = {
    hour: { unit: "HOUR", text: i18n.t("hour") },
    day: { unit: "DAY", text: i18n.t("day") },
  };
  if (unit != undefined) {
    return options[unit.toLowerCase()];
  }

  return null;
}

function timeChargedUnitLabels(unit: string) {
  const options: any = {
    'hour': { quantity: i18n.t('hours'), cost: i18n.t('hourCost') },
    'day': { quantity: i18n.t('days'), cost: i18n.t('dayCost') }
  }

  if (unit) {
    return options[unit.toLowerCase()];
  }
  return options['hour'];
}
function itemUnitAbbreviation(unit: string | undefined) {
  const options: Record<string, any> = {
    'pieces': i18n.t('abbrevPieces'),
    'meters': i18n.t('abbrevMeters'),
    'centimeters': i18n.t('abbrevCentimeters'),
    'squaremeters':  i18n.t('abbrevSquareMeters'),
  };

  if (unit != undefined) {
    return options[unit.toLowerCase()];
  }

  return options['pieces'];
}

function calculateTimeChargingCost(
  action: Record<string, any>,
  isAppointment = false
) {
  // appointment & charging by the hour
  if (isAppointment && action.unit?.unit?.toLowerCase() === "hour") {
    const endDate = dayjs(action.scheduled_end);
    if (endDate.diff(action.scheduled_start, "hour", true) < 1) {
      action.unit_quantity = 1;
    } else {
      action.unit_quantity = Math.round(
        endDate.diff(action.scheduled_start, "hour", true)
      );
    }
  }

  // appointment & charging by day
  if (isAppointment && action.unit?.unit?.toLowerCase() === "day") {
    const endDate = dayjs(action.scheduled_end);
    if (endDate.diff(action.scheduled_start, "day", true) < 1) {
      action.unit_quantity = 1;
    } else {
      action.unit_quantity = Math.round(
        endDate.diff(action.scheduled_start, "day", true)
      );
    }
  }

  if (Number(action.unit_quantity) && Number(action.unit_cost)) {
    action.cost = Number(
      (Number(action.unit_quantity) * Number(action.unit_cost)).toFixed(2)
    );
  }
}

function timeChargedUnitLabelsInfo(unit: string, quantity: string | number) {
  if(typeof quantity === 'string') {
    quantity = Number(quantity);
  }

  const singularOptions: any = {
    'hour': i18n.t('hourLowerCase'),
    'day': i18n.t('dayLowerCase')
  };

  const pluralOptions: any = {
    'hour': i18n.t('hoursLowerCase'),
    'day': i18n.t('daysLowerCase')
  };

  if (unit && quantity) {
    if(quantity > 1) {
      return pluralOptions[unit.toLowerCase()];
    }
    return singularOptions[unit.toLowerCase()];
  }
}

function jobItemUnitInfo(item: Record<string, any>) {
  if(item.is_time_charged && item.unit && item.unit_quantity && item.unit_cost) {
    return `(${item.unit_quantity} ${timeChargedUnitLabelsInfo(item.unit, item.unit_quantity)} x ${item.unit_cost}€)`
  }
  return '';
}

function itemUnitInfoLabel(item: Record<string, any>) {
  if(item.is_unit_based && item.unit_cost != undefined && item.unit_quantity && item.unit) {
    return `(${item.unit_quantity}${itemUnitAbbreviation(item.item_unit_text)} x ${item.unit_cost}€)`;
  }
  return '';
}

export {
  formatDate,
  formatDateCalendar,
  formatOnlyDate,
  calcDuration,
  appointmentStatusToName,
  appointmentStatusToColor,
  addOpacity,
  roundMinutes,
  formatDatesRangeText,
  sortDateRange,
  statusCodeMsg,
  primaryContactExists,
  formatDateWithoutSeconds,
  taskStatusToName,
  taskStatusToColor,
  formatAmount,
  customSearch,
  randomHexColor,
  hexColorRemoveLastTwoDigits,
  sortTableDates,
  isDecimalNumber,
  getOptimalTextColor,
  entryIcon,
  taskColor,
  setGoogleMapsInfoToModel,
  getGoogleMapsInfo,
  periodToText,
  isNumber,
  periodOptionToNumber,
  periodOptionToComboText,
  periodOptionToComboValue,
  periodOptionToLabel,
  getDurationText,
  isGasCompany,
  pagesExportLimit,
  templatesFilenameLanguage,
  mapTileProviders,
  timeChargedUnitOption,
  timeChargedUnitLabels,
  calculateTimeChargingCost,
  mergedAddressAndArea,
  timeChargedUnitLabelsInfo,
  jobItemUnitInfo,
  itemUnitAbbreviation,
  itemUnitInfoLabel,
};
