
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { commonModule, installationsModule, companyModule } from '@/store/modules/store-accessor'
import { setGoogleMapsInfoToModel } from "@/helpers/data";
import MapAddressCoordinates from '@/components/Map/MapAddressCoordinates.vue'

@Component({
  components: {
    MapAddressCoordinates
  }
})
export default class EditInstallationModal extends Vue {
  @Prop()
  payload!: any;

  markerCoords = [this.currInstallation.info.latitude, this.currInstallation.info.longitude];
  lat = this.markerCoords[0];
  long = this.markerCoords[1];

  get currInstallation () {
    return installationsModule.currentInstallation;
  }
 
  installationModel = {
   tag: this.currInstallation.info.tag_id.split('_').pop(),
   client: this.currInstallation.info.client_is_business ? {name: this.currInstallation.info.client_entity_name + " (" + this.currInstallation.info.client_lastname + " " + this.currInstallation.info.client_firstname + ")", id: this.currInstallation.info.user_id}: {name: this.currInstallation.info.client_lastname + " " + this.currInstallation.info.client_firstname, id: this.currInstallation.info.user_id},
   owner: this.currInstallation.info.owner_is_business ? {name: this.currInstallation.info.owner_entity_name + " (" + this.currInstallation.info.owner_lastname + " " + this.currInstallation.info.owner_firstname + ")", id: this.currInstallation.info.owner_id} : {name: this.currInstallation.info.owner_lastname + " " + this.currInstallation.info.owner_firstname, id: this.currInstallation.info.owner_id},
   address: this.currInstallation.info.address || '',
   area: this.currInstallation.info.area,
   zip_code: this.currInstallation.info.zip_code || '',
   floor: this.currInstallation.info.floor || '',
   is_premium: this.currInstallation.info.is_premium,
   notes: this.currInstallation.info.notes || '',
   latitude: this.currInstallation.info.latitude,
   longitude: this.currInstallation.info.longitude,
   id: this.currInstallation.info.id,
   view: this.payload.view,
   description: this.currInstallation.info.description || '',
  }

  evt:any;

  tagRule= [
    (val: any) => !!val || this.$i18n.t("tag_required"),
  ]
  clientRule= [
  (val: any) => ((!!val || val == '') && val.name.length > 0) || this.$i18n.t("tenant_required"),
  ]

  areaRule= [
    (val: any) => !!val || this.$i18n.t("area_required"),
    (val: any) => val.length <= 30 || this.$i18n.t("form_area_length_rule"),
  ]

  addressRule= [
    (val: any) => val?.length <= 100 || this.$i18n.t("form_address_length_rule"),
  ]

  zipCodeRule= [
    (val: any) => val?.length <= 15 || this.$i18n.t("form_zipCode_length_rule"),
  ]
  notesRule= [
    (val: any) => {
      if(val != null) return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ]

  descriptionRule = [
    (val: any) => {
      if(val != null) return val.length <= 80 || this.$i18n.t("installationDescriptionLengthRule");
      else return true;
    },
  ]

  get clientsCombo () {
    return commonModule.clientsCombo.map((item: any) => {
      let client_arr = item.is_business
      ? [`${item.entity_name} (${item.name})`]
      : [`${item.name}`]
      return {...item, name: client_arr.filter(Boolean).join(' | ') }
    });
  }

  async hideModal () {
    commonModule.hideModal();
    installationsModule.clearNewInstallationCoordinates();
  }

  doUpdateInstallation () {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      installationsModule.editInstallation(this.installationModel);
      this.hideModal();  
    }
  }

  async mounted() {
    commonModule.initSnackbar({});
    await commonModule.getClientsCombo();
    await companyModule.getCompanyInfo();
  }

  getAddressData(addressData:any) {
        this.installationModel.area = addressData.locality;
        this.installationModel.zip_code = addressData.postal_code ? addressData.postal_code.replace(/\s/g, "") : '';
        this.installationModel.latitude = addressData.latitude;
        this.installationModel.longitude = addressData.longitude;
  }


  updateAddressField(addressInfo: any) {
    this.installationModel.address = addressInfo.address;
    this.installationModel.area = addressInfo.area;
    this.installationModel.zip_code = addressInfo.zip_code;
    this.installationModel.latitude = addressInfo.latitude;
    this.installationModel.longitude = addressInfo.longitude;
  }

  isNumber(evt:any) {
      evt = evt || window.event;
      let charCode = evt.which || evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
      return false;
  }

  get descriptionIsEnabled() {
    return (companyModule.company?.preferences as any)?.installation_description;
  }
  get installationCooridnates() {
    return installationsModule.newInstallationCoordinates;
  }

  @Watch('installationCooridnates')
  updateCoordinates() {
    if(installationsModule.newInstallationCoordinates.length == 2) {
      setGoogleMapsInfoToModel(this.installationModel, installationsModule.newInstallationCoordinates[0], installationsModule.newInstallationCoordinates[1]);
    }
  }

};
